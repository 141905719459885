import React, { useState } from "react"
import Img from "gatsby-image"
import { withPreview } from "gatsby-source-prismic"
import styled from "styled-components"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import LocationNavigation from "../components/location/navigation"
import { useResortPageData } from "../hooks/useResortPageData"
import { fade } from "../styles/animations/fadeIn"
import Filter from "../components/atoms/filter"

// Was previously resort page
const RoomsLayoutPage = ({ data: previewData }) => {
  const { uid, bookData, data } = useResortPageData(previewData)
  const [current, setCurrent] = useState<number>(0)
  const navItems = data.navigation_images.map(nav => nav.navigation_text)

  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <Container>
        <PageHeader
          title={data.title}
          tagline={data.tagline}
          text={data.text}
        />
        <Content>
          <NavigationContainer>
            <Filter
              options={navItems}
              onChange={setCurrent}
              breakpoint="750px"
            />

            <LocationNavigation
              navItems={navItems}
              setImage={setCurrent}
              current={current}
            />
          </NavigationContainer>
          <MapImageContainer>
            <Img
              fluid={data.navigation_images[current].navigation_image.fluid}
              alt={
                data.navigation_images[current].navigation_image.alt ||
                `resort map ${current + 1}`
              }
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </MapImageContainer>
        </Content>
      </Container>
    </Layout>
  )
}

export default withPreview(RoomsLayoutPage)

const Container = styled.div``
const Content = styled.div``
export const NavigationContainer = styled.div`
  padding: 80px 0 64px 0;
`
export const MapImageContainer = styled.div`
  max-width: 80%;
  margin: 0 auto 120px auto;

  .gatsby-image-wrapper {
    min-height: 250px;
    animation: ${fade} 0.2s ease-in;
  }
`
