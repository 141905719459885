import React from "react"
import styled from "styled-components"
import selectArrow from "../../assets/icons/arrowDown.png"

interface FilterProps {
  options: string[]
  onChange: any
  breakpoint: string
}

/**
 * Custom select for filtering
 * @constructor
 */
const Filter: React.FC<FilterProps> = ({ options, onChange, breakpoint }) => {
  return (
    <CustomSelect breakpoint={breakpoint}>
      <Select
        aria-label="filter"
        name="filter"
        id="filter"
        onChange={e => onChange(e.target.value)}
      >
        <Option value="0">Filter by</Option>
        {options.map((option, i) => (
          <Option key={i} value={i}>
            {option}
          </Option>
        ))}
      </Select>
      <SelectArrow src={selectArrow} alt="select arrow" />
    </CustomSelect>
  )
}

export default Filter

const CustomSelect = styled.div<{ breakpoint: string }>`
  position: relative;
  width: 200px;
  margin: 0 auto;
  @media (min-width: ${({ breakpoint }) => breakpoint}) {
    display: none;
  }
`

const Select = styled.select`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  display: flex;
  justify-content: center;
  padding: 16px;
  width: 100%;
  margin: 0 auto;
  text-transform: capitalize;
  color: var(--primary-text);
  background-color: #eceae8;
  border: none;
`

const Option = styled.option`
  text-transform: capitalize;
  padding: 16px;
  color: var(--primary-text);
  background: white;
  border: none;
`
const SelectArrow = styled.img`
  position: absolute;
  right: 16px;
  z-index: 1;
  filter: invert(55%) sepia(28%) saturate(436%) hue-rotate(151deg)
    brightness(94%) contrast(97%);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  height: 10px;
`
