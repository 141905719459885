import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useResortPageData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicResortPage {
        _previewable
        uid
        data {
          title {
            html
          }
          text {
            text
          }
          tagline {
            text
          }

          navigation_images {
            navigation_image {
              fluid(maxHeight: 400) {
                src
                sizes
                srcSet
                aspectRatio
              }
            }
            navigation_text
          }
          body {
            ... on PrismicResortPageBodyBook {
              id
              slice_type
              primary {
                button_text
                link {
                  url
                  link_type
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const { uid, data } = mergedData.prismicResortPage
  return {
    uid,
    bookData: data.body[0] ? data.body[0].primary : undefined,
    data,
  }
}
