import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const LocationNavigation = ({ navItems, setImage, current }) => {
  return (
    <Container>
      {navItems.map((navItem, i) => {
        return (
          <NavItem
            key={i}
            isActive={current === i}
            onClick={() => setImage(i)}
            id={i}
          >
            {navItem}
          </NavItem>
        )
      })}
      <StyledLink to="/resort-map">Resort Map</StyledLink>
    </Container>
  )
}

export default LocationNavigation

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  @media (max-width: 750px) {
    display: none;
  }
`

const NavItem = styled.p<{ isActive: boolean }>`
  text-transform: uppercase;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-align: center;
  margin: 0 24px;
  padding: 4px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 0 var(--primary-text);
  }
  box-shadow: ${({ isActive }) =>
    isActive ? "0 1px 0 var(--primary-text)" : "none"};
  @media (max-width: 1000px) {
    margin: 0 8px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: var(--primary-text);
  font-family: "Moderat Medium", sans-serif;
  text-align: center;
  margin: 0 24px;
  padding: 4px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 0 var(--primary-text);
  }
  @media (max-width: 1000px) {
    margin: 0 8px;
  }
`
